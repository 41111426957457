import type { NextPage } from 'next'
import styles from '../shared/styles/Home.module.css'
import { useRouter } from 'next/router'

const Home: NextPage = () => {
    const router = useRouter()

    router.replace('/belltower/livedashboard')

    return (
        <div className={styles.container}>
            <h1>Welcome to Belfry</h1>
            <h3>Redirecting you...</h3>
        </div>
    )
}

export default Home
